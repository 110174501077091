<template>
  <div class="banner">
    <img class="w-100" :src="src" :alt="alt"/>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  props: [
    'src',
    'alt'
  ],
  data() {
    return {
    };
  },
  created() {
    this.$emit('widgetLoaded');
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
